<template>
  <div>
    
    <b-row>
      <b-col cols="12" md="12">
        <div
          class=""
          style="margin-left: auto; margin-right: 0; text-align: right"
        >
          
          <router-link :to="'/apps/train-n-hire/add-batch'" >
            <b-button variant="primary">
              <span class="text-nowrap">Add New Batch</span>
            </b-button>
          </router-link>
        </div>
      </b-col>
    </b-row>
    <div v-if="batches.length > 0">
      <b-card 
        v-for="batch in batches"
        :key="batch.id"
        no-body
        class="card-browser-states mt-50"
        style="padding: 10px 10px 0px 10px !important;"
      >
        <router-link :to="{ name: 'apps-train-n-hire-view', params: { id: batch.id } }">
          <b-card-header>
            <b-row style="color: #5e5873 !important; font-size: 0.9rem">
                <b-col md="4" xl="4" xs="6" sm="6">
                  <div class="mb-1">
                    <h4 class="mb-0">
                      {{  batch.batch_id }} - {{  batch.name }}
                    </h4>  
                  </div>
                </b-col>
                <b-col md="4" xl="4" xs="6" sm="6">
                  <div class="mb-1">
                    <h6 class="mb-0">
                      <span>
                        {{ batch.client }}
                      </span>
                    </h6>
                  </div>
                </b-col>
                <b-col md="4" xl="4" xs="6" sm="6">
                  <div class="mb-1">
                    <h6 class="mb-0">
                      <span>
                        {{ batch.location }}
                      </span>
                    </h6>
                  </div>
                </b-col>
              </b-row>                          
            
          </b-card-header>

          <b-card-body style="padding: 1rem !important">
            <b-row style="color: #5e5873 !important; font-size: 0.9rem">
                <b-col md="4" xl="4" xs="6" sm="6">
                  <div class="mb-1">
                    <h6 class="mb-0">
                      <span>
                        {{  batch.startDate }} - {{  batch.startDate }}
                      </span>  
                    </h6>
                  </div>
                </b-col>
                <b-col md="4" xl="4" xs="6" sm="6">
                  <div class="mb-1">
                    <h6 class="mb-0">
                      <span>
                        {{  batch.trainer_name }}
                      </span>
                    </h6>
                  </div>
                </b-col>
                <b-col md="4" xl="4" xs="6" sm="6">
                  <div class="mb-1">
                    <h6 class="mb-0">
                      <span>
                        {{  batch.manager_name }}
                      </span>
                    </h6>
                  </div>
                </b-col>
              </b-row>  
          </b-card-body>
        </router-link>
      </b-card>
    </div>
    <div v-else>
      <b-card>
        <h5 variant="danger">
          {{  error }}
        </h5>
      </b-card>
    </div>
    <!--</template>
  </b-sidebar>-->
  </div>
</template>

<script>
import {

  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BCard,
  BRow,
  BCol,
  BFormDatepicker,
  BFormFile,
  BFormTextarea,
  BButton, BSidebar, VBToggle
} from "bootstrap-vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TrainingBatchService from '@/services/training-batch-service'
import TrainingCandidateService from '@/services/training-candidate-service'

export default {
  components: {
    BSidebar, BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BCard,
    BRow,
    BCol,
    BFormDatepicker,
    BFormFile,
    BFormTextarea,
    // Form Validation
    
  },
  
  data() {
    return {
      batches: [],
      error
    }      
  },
  
  created() {
    this.batches = [{
      name: 'Batch-1',
      client: 'Genpact',
      location: 'Hyderabad',
      startDate: '21-Jan-2023',
      endDate: '21-Jan-2023',
      trainer_name: 'Ritesh Tiwari',
      manager_name: 'RItesh Tiwari'
    },
    {
      name: 'Batch-2',
      client: 'Genpact',
      location: 'Hyderabad',
      startDate: '21-Jan-2023',
      endDate: '21-Jan-2023',
      trainer_name: 'RItesh Tiwari',
      mamanger_name: 'RItesh Tiwari'
    }]
    this.getBatches()
  },
  methods: {
    getBatches() {
      TrainingBatchService.getAllTrainingBatches().then(res => {
        console.log(res)
        if(res.status === 200) {
          this.batches = res.data
        } else if (res.status === 404) {
          this.batches = []
          this.error = "No Batches added in the system yet"
        } else if (res.status === 500) {
          this.batches = []
          this.error = "Some error occurred, error meesage: " + res.message + "    Error: " + res.error
        } else if ((res.status === 401 ) || (res.status === 403 )) {
          window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
          this.batches = []
          this.error = "Session Expired... Redirecting to Login Page"
          localStorage.clear();
              this.$router.push({ name: "auth-login" });
        } 
        
      })
    }
    


  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-candidate-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.b-sidebar.sidebar-lg {
  width: 45rem;
  padding-left: 15px;
  padding-right: 15px;
}
.hidden{
  display: none;
}
</style>
